<template>
  <div class="page">
    <div class="content-box section">
      <el-form
          ref="model"
          :model="model"
          :rules="rules"
          class="demo-ruleForm"
          label-width="120px"
      >
        <easy-card title="基本信息">
          <icon-button slot="extra" icon="return" @click.native="goBack"/>
          <el-row>
            <el-col :span="12">
              <el-form-item label="商户名称" prop="leaseTitle">
                <el-input
                    v-model="model.leaseTitle"
                    placeholder="请输入-商户名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手机号" prop="parkingId">
                <el-input
                    v-model="model.leaseTitle"
                    placeholder="请输入-登录手机号"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="登录密码" prop="leaseTitle">
                <el-input
                    v-model="model.leaseTitle"
                    placeholder="请输入-密码"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="品牌名称" prop="parkingId">
                <el-input
                    v-model="model.leaseTitle"
                    placeholder="请输入-品牌名称"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="结算方名称" prop="leaseTitle">
                <Settlement v-model="model.settlementpartyId"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="车场名称" prop="parkingId">
                <SuggestParking v-model="model.parkingLotId"/>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="运营负责人" prop="leaseTitle">
                <el-input
                    v-model="model.leaseTitle"
                    placeholder="请输入-运营负责人手机号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="推荐人" prop="parkingId">
                <el-input
                    v-model="model.leaseTitle"
                    placeholder="请输入-推荐人手机号"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="店铺照片" prop="leaseTitle">
                <el-upload
                    ref="graphImageUpload"
                    :file-list="graphFileList"
                    :http-request="doGraphImageUpload"
                    :on-remove="onGraphImageRemove"
                    action=""
                    list-type="picture-card"
                >
                  <span>点击上传</span>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="营业执照" prop="parkingId">
                <el-upload
                    ref="graphImageUpload"
                    :file-list="graphFileList"
                    :http-request="doGraphImageUpload"
                    :on-remove="onGraphImageRemove"
                    action=""
                    list-type="picture-card"
                >
                  <span>点击上传</span>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </easy-card>

        <easy-card title="运营规则">
          <el-row>
            <el-col :span="12">
              <el-form-item label="发券对象" prop="leaseTitle">
                <el-select
                    v-model="model.unlicensedCarIntoType"
                    placeholder="请选择发券对象"
                    style="width: 50%"
                >
                  <el-option label="仅用户" value="1"></el-option>
                  <el-option label="仅车辆" value="2"></el-option>
                  <el-option label="用户与车辆" value="3"></el-option>
                </el-select>
                <el-select
                    v-model="model.unlicensedCarIntoType"
                    style="width: 50%"
                >
                  <el-option label="需注册" value="1"></el-option>
                  <el-option label="无需注册" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="停车券短信提醒" prop="parkingId">
                <el-select
                    v-model="model.unlicensedCarIntoType"
                    placeholder="请选择发券对象"
                    style="width: 100%"
                >
                  <el-option label="不启用" value="1"></el-option>
                  <el-option label="启用" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="14">
              <el-form-item label="发券类型" prop="parkingId">
                <el-checkbox-group v-model="checkList">
                  <div class="checkbox-position">
                    <el-checkbox label="1">面额</el-checkbox>
                    <el-radio-group v-model="radio" class="radio-position">
                      <div>
                        <el-radio label="1" style="width:80px;">定额</el-radio>
                        <el-input v-model="model.input2" class="input-margin" placeholder="请输入面额券金额"
                                  size="small" style="width:220px;">
                          <template slot="append">元</template>
                        </el-input>
                      </div>
                      <div>
                        <el-radio label="2" style="width:80px;">预留离场时长</el-radio>
                        <el-input v-model="model.input2" class="input-margin" placeholder="请输入预留离场时长"
                                  size="small" style="width:220px;">
                          <template slot="append">分钟</template>
                        </el-input>
                      </div>
                      <div>
                        <el-radio label="3" style="width:80px;">自定义</el-radio>
                        <el-input v-model="model.input2" class="input-margin" placeholder="请输入预设金额"
                                  size="small" style="width:220px;">
                          <template slot="append">元</template>
                        </el-input>
                        <el-input v-model="model.input2" class="input-margin" placeholder="请输入最大可发放面值"
                                  size="small" style="width:220px;">
                          <template slot="append">元</template>
                        </el-input>
                      </div>
                    </el-radio-group>
                  </div>
                  <div>
                    <el-checkbox label="2">定额免单券</el-checkbox>
                    <el-input v-model="model.input2" class="input-margin" placeholder="请输入兑换金额"
                              size="small" style="width:220px;">
                      <template slot="append">元</template>
                    </el-input>
                  </div>
                  <div>
                    <el-checkbox label="3">单笔免单券</el-checkbox>
                  </div>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="泊位短租" prop="leaseTitle">
                <el-select
                    v-model="model.unlicensedCarIntoType"
                    style="width: 100%"
                >
                  <el-option label="免费添加" value="1"></el-option>
                  <el-option label="扣券添加" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="parkingId">
                <el-select
                    v-model="model.unlicensedCarIntoType"
                    style="width: 100%"
                >
                  <el-option label="副券" value="1"></el-option>
                </el-select>

                <el-select
                    v-model="model.unlicensedCarIntoType"
                    style="width: 50%"
                >
                  <el-option label="全部" value="1"></el-option>
                  <el-option label="停车券" value="1"></el-option>
                  <el-option label="副券" value="1"></el-option>
                </el-select>

                <el-input v-model="model.input2" placeholder="请输入扣券金额" style="width:50%">
                  <template slot="append">元/小时</template>
                </el-input>

              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="定时发放副券" prop="leaseTitle">
                <el-select
                    v-model="model.unlicensedCarIntoType"
                    style="width: 100%"
                >
                  <el-option label="不启用" value="1"></el-option>
                  <el-option label="启用" value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="发券额度限制" prop="leaseTitle">
                <el-input v-model="model.input2" placeholder="请输入-最低充值剩余额度(仅折扣券和免单券)"
                          style="width:100%">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="短信通知车主" prop="leaseTitle">
                <el-select
                    v-model="model.unlicensedCarIntoType"
                    style="width: 100%"
                >
                  <el-option label="不启用" value="1"></el-option>
                  <el-option label="启用" value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </easy-card>
        <el-button
            class="xxd-button_save"
            type="primary"
            @click="save('model')"
        >保存
        </el-button
        >
      </el-form>
    </div>
  </div>
</template>


<script>
import Settlement from "@/components/Settlement";
import SuggestParking from "@/components/SuggestParking";
import {doUploadImage} from "@/api/parking";

export default {
  data() {
    return {
      model: {},
      checkList: [],
      radio: "",
      graphFileList: [],
    };
  },
  components: {
    Settlement,
    SuggestParking,
  },
  methods: {
    //返回商户管理列表
    goBack() {
      this.$router.back();
    },
    // 图片删除
    onGraphImageRemove(file, fileList) {
      this.model.parkingPlanGraphImages = fileList.map((item) => item.id);
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader(); //定义方法读取文件
        reader.readAsDataURL(file); //开始读文件  本身是图片的二进制数据 进行base64加密形成字符串
        reader.onload = () => resolve(reader.result); //成功返回对应的值 reader.result可以直接放在img标签中使用
        reader.onerror = (error) => reject(error); //失败返回失败信息
      });
    },
    // 上传图片
    async doGraphImageUpload(file) {
      const isLimit = file.file.size / 1024 < 1000;
      if (!isLimit) {
        this.$message.warning("图片大小不能超过1M! 请重新上传");
        // this.$refs.graphImageUpload.clearFiles();
        return;
      }
      const base64 = await this.getBase64(file.file);
      const res = await doUploadImage(base64);
      if (res && res.code === 30 && res.result) {
        this.model.parkingPlanGraphImages.push(res.returnObject.id);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.checkbox-position {
  margin-top: -74px;
}

.radio-position {
  margin-top: 100px;
  margin-left: 20px;
}

.input-margin {
  margin-left: 10px;
  margin-top: 4px;
}
</style>
